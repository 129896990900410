import React from 'react';
import './wash.css';
import { InputNumber,Input} from 'antd';

const WashBox=(props)=>{
    const {title,onChange}=props
    return(
                     <section className='wash_service_clst'>
                       <div className='wash_one_testf'>{title}</div>
                       <div className='wash_one_test2s'><Input onChange={(e)=>onChange("duration",e.target.value)} style={{ width: '60px',height:'40px' }} size="small" defaultValue={0}/></div>
                       <div className='wash_one_test2s'><Input onChange={(e)=>onChange("temp",e.target.value)} style={{ width: '60px',height:'40px' }} size="small"  defaultValue={0}/></div>
                       <div className='wash_one_testf'><Input onChange={(e)=>onChange("final_conductivity",e.target.value)} style={{ width: '60px',height:'40px' }} size="small" defaultValue={0}/></div>
                       <div className='wash_one_test2s'><Input onChange={(e)=>onChange("flow_gpm",e.target.value)} style={{ width: '60px',height:'40px' }} size="small"  defaultValue={0}/></div>
                       <div className='wash_one_test2s' style={{border:"none"}}><Input onChange={(e)=>onChange("total_flow",e.target.value)} style={{ width: '60px',height:'40px' }} size="small" defaultValue={0}/></div>
                       </section>
    )
}

export default WashBox;